import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys, decamelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { RootState } from "main/javascripts/store";
import { Cruise } from "main/javascripts/types/cruise";

const key = "cruise";
const adapter = createEntityAdapter<Cruise.Cruise>();

const initialState = adapter.getInitialState({
  cruise: null,
  page: 0,
  numPerPage: 0,
  totalNum: 0,

  isImageModalDisplayed: false,

  loading: false,
  errors: null,
});

/** Async **/
export const fetchCruise = createAsyncThunk<
  {
    cruise: Cruise.Cruise;
  },
  {
    uniqueId: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCruise`, async (args, { rejectWithValue }) => {
  try {
    const { uniqueId, params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/cruises/${uniqueId}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      cruise: result.data.cruise,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const cruiseSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initCruise: (state, action: PayloadAction<Cruise.Cruise>) => {
      state.cruise = action.payload;
    },
    setIsCruiseImageModalDisplayed: (state, action) => {
      state.isImageModalDisplayed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruise.fulfilled, (state, action) => {
        state.cruise = action.payload.cruise;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
export const {
  selectById: cruiseByIdSelector,
  selectIds: cruiseIdsSelector,
  selectEntities: cruiseEntitiesSelector,
  selectAll: cruisesSelector,
  selectTotal: totalCruisesSelector,
} = adapter.getSelectors((state: RootState) => state.cruise);

const stateSelector = (state: { [key]: Cruise.CruiseEntityState }) =>
  state[key];

export const cruiseSelector = createSelector(
  stateSelector,
  (state) => state.cruise
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const isImageModalDisplayedSelector = createSelector(
  stateSelector,
  (state) => state.isImageModalDisplayed
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initCruise, setIsCruiseImageModalDisplayed } =
  cruiseSlice.actions;
