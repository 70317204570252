export const seo: any = {
  siteName: "TabiChat",
  title: "Booking of overseas travel tours, hotels, and flights",
  titleConsultationConditionEdit:
    "Additional conditions for travel consultation",
  titleConsultationNew: "Entering conditions for travel consultation",
  titleCruiseConsultationNew: "Entering conditions for cruise consultation",
  titleHotelIndex: "Hotel Search",
  titleHotelShow: "{{name}} Hotel Reservation",
  titleFlightIndex: "Flight Search",
  titleFlightShow: "Flight Reservation",
  titleEmailUserSignIn: "Email Log in",
  description:
    "With TabiChat, you can book tours, hotels, and flights for your overseas trip. Chat with us and our concierge will suggest a plan for you.",
  descriptionConsultationConditionEdit:
    "You can add conditions to your travel consultation.",
  descriptionConsultationNew:
    "You can enter conditions for travel consultation.",
  descriptionCruiseConsultationNew:
    "You can enter conditions for cruise consultation.",
  descriptionHotelIndex:
    "TabiChat allows you to book hotels with the best deals and combinations!。",
  descriptionHotelShow:
    "Information on accommodation and reservations at {{name}}. TabiChat allows you to book hotels with the best deals and combinations.",
  descriptionEmailUserSignIn:
    "After logging in, you can [Reservation application|Search].",
  titleCruiseIndex: "Cruise Search",
  titleCruiseShow: "Cruise Details",
};
