import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
// import { Store } from "redux";
import { stringify } from "qs";
import { store } from "main/javascripts/store";
import { HeaderContainer } from "main/javascripts/containers/organisms/Header/HeaderContainer";
import {
  defaultStyles,
  extended,
  GLOBALS,
} from "main/javascripts/styles/globalStyle";
import i18n from "i18n";
import { MainBlock } from "main/javascripts/components/atoms/Block/MainBlock";
import { MainHead } from "main/javascripts/components/head/MainHead";
import { SearchBlock } from "main/javascripts/components/search/SearchBlock";
import { RecommendedInfo } from "main/javascripts/components/organisms/Search/RecommendedInfo";
import { getAbsoluteUrl } from "main/javascripts/utils/UrlUtil";
import { useConstructor } from "main/javascripts/hooks/common/useConstructor";
import { fetchPostsIndexPage } from "main/javascripts/api/PostPageApi";
import { useUserSocialAutoSignIn } from "main/javascripts/hooks/common/useUserSocialAutoSignIn";
import { LiffTypes } from "main/javascripts/constants/LiffTypes";
import { CategoryTab } from "main/javascripts/features/search/components/CategoryTab";
import { Search } from "main/javascripts/features/search/components/Search";
import { changeSearchCategory } from "main/javascripts/features/search/searchPageSlice";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { SearchChatEntry } from "main/javascripts/features/search/components/SearchChatEntry";
// import { Footer } from "main/javascripts/components/footer/Footer";
import { FooterContainer } from "main/javascripts/containers/organisms/Footer/FooterContainer";
// import { loadVConsole } from "main/utils/DebugUtil";
import { Post } from "main/javascripts/types/post";
import { Region } from "main/javascripts/types/region";
import { PostIndexMain } from "main/javascripts/features/post/components/PostIndexMain";
import { initPosts } from "main/javascripts/features/post/postSlice";

interface IProps {
  // currentUser: CurrentUser | null;
  // utmSource: string | null;
  // lineRegisterUrl: string | null;
  // lineType: string | null;
  posts?: Post.Post[];
  totalNum?: number;
  page?: number;
  numPerPage?: number;
  region?: Region.Region;
  rankings: Post.Post[];
  popularTags: any[];
  tag: any;
  locale: string;
  origin: string;
}
// loadVConsole(2000);
function Index(props: IProps) {
  const { locale, origin } = props;
  const {
    posts,
    totalNum,
    page,
    numPerPage,
    region,
    rankings,
    popularTags,
    tag,
  } = props;

  useConstructor(() => {
    if (process.env.HOSTING_DOMAIN) {
      store.dispatch(
        initPosts({
          posts,
          totalNum,
          page,
          numPerPage,
          rankings,
          popularTags,
          tag,
          region,
        })
      );
    }
    store.dispatch(changeSearchCategory(searchCategories.hotel));
    void i18n.changeLanguage(locale);
    extended.css(defaultStyles[GLOBALS]);
  });

  useUserSocialAutoSignIn(store, LiffTypes.top);

  useEffect(() => {
    if (process.env.HOSTING_DOMAIN) {
      store.dispatch(
        initPosts({
          posts,
          totalNum,
          page,
          numPerPage,
          rankings,
          popularTags,
          tag,
          region,
        })
      );
    }
  }, [posts, totalNum, page, numPerPage, tag]);

  return (
    <Provider store={store}>
      <MainHead origin={origin} />
      <I18nextProvider i18n={i18n}>
        <>
          <HeaderContainer key={0} />
          <MainBlock key={1}>
            {process.env.HOSTING_DOMAIN ? (
              <PostIndexMain />
            ) : (
              <>
                <SearchBlock>
                  <CategoryTab />
                  <Search />
                </SearchBlock>
                <SearchChatEntry />
                <RecommendedInfo />
              </>
            )}
          </MainBlock>
          <FooterContainer key={3} />
        </>
      </I18nextProvider>
    </Provider>
  );
}

export async function getServerSideProps(ctx) {
  const { origin } = getAbsoluteUrl(ctx.req);
  const locale = ctx.locale;
  const param = ctx.query;

  if (process.env.HOSTING_DOMAIN) {
    const paramString: string = stringify(param, { arrayFormat: "repeat" });
    const { data } = await fetchPostsIndexPage(paramString, ctx.req.headers, {
      isServerSide: true,
    });
    return {
      props: {
        origin,
        locale,
        ...data,
      },
    };
  }

  return {
    props: {
      origin,
      locale,
    },
  };
}

export default Index;
