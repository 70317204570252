export const common = {
  checkinDate: "Checkin date",
  checkoutDate: "Checkout date",
  departureDate: "Departing date",
  arrivalDate: "Arrival date",
  createdDate: "Created date",
  returnDate: "Returning date",
  cabinClass: "Preferred class",
  destination: "Going to",
  query: "Keyword for query",
  origin: "Flying from",
  date: "Date",
  schedule: "schedule",
  people: "Number of people",
  flightAgesOfChildren: "Child {{index}} age",
  agesOfChild: "Child {{index}} age",
  stopCount: "Stop count",
  departureTime: "Departure time",
  arrivalTime: "Arrival time",
  totalPrice: "Total price",
  priceIncludingOption: "Price inncluding option",
  airport: "Airport",
  unspecified: "Unspecified",
  flightNonstop: "Nonstop",
  flightStopUpTo: "Up to {{count}} stops",
  flightOverNightUpTo: "Up to {{count}} nights",
  outboundCity: "Outbound",
  returnCity: "Return",
  departureCity: "City {{index}}",
  lastArrivalCity: "Last arrival city",
  price: "Price",
  additionalPrice: "Additional price",
  airportCity: "City {{index}}",
  recommendedFirst: "Recommended first",
  cheapestFirst: "Cheapest first",
  highRankFirst: "High rank first",
  fastestFirst: "Fastest first",
  addItinerary: "+ Add flight",
  removeItinerary: "- Remove flight",
  numberOfRooms: "Rooms",
  room: "Room",
  hotelNumberOfAdult: "Adults(18+)",
  hotelNumberOfChildren: "Children(0-17)",
  flightNumberOfAdult: "Adults(12+)",
  flightNumberOfChildren: "Children(0-11)",
  chatUnitName: "Chat User Name",
  userId: "User Id",
  adult: "Adult",
  child: "Child",
  infant: "Infant",
  common: "Common",
  authorOnly: "Author Only",
  bot: "Bot",
  createNew: "New",
  list: "List",
  text: "Text",
  image: "Image",
  quickReply: "Quick Reply",
  arrangementType: "Arrangement type",
  tourTitle: "Tour title",
  tourId: "Tour ID",
  startDateTime: "Start date/time",
  endDateTime: "End date/time",
  startDate: "Start date",
  endDate: "End date",
  memo: "Memo",
  description: "Description",
  cancelPolicy: "Cancel policy",
  participant: "Participant",
  arrangementDone: "Done",
  totalFee: "Total fee",
  totalArrangementFee: "Total arrangement fee",
  reservationNumber: "Reservation number",
  passwordNumber: "Password Number",
  lastName: "Last name",
  firstName: "First name",
  address: "Address",
  phoneNumber: "Phone number",
  fee: "Fee",
  arrangementFee: "Arrangement fee",
  arrangementFeeShort: "Fee",
  cancellationCharge: "Cancellation charge",
  cancellationChargeIncludingFee:
    "Cancellation charge including ticketing company fee",
  name: "Name",
  status: "Status",
  gender: "Gender",
  birthday: "Date of birth",
  birthdayAmadeusFormat: "Date of birth (Amadeus notation)",
  rentalCarTitle: "Rental car title",
  rentalCarId: "Rental car ID",
  wifiTitle: "WiFi title",
  wifiId: "WiFi ID",
  railwayTitle: "Railway title",
  railwayId: "Railway ID",
  cruiseTitle: "Cruise title",
  cruiseId: "Cruise ID",
  insuranceTitle: "Insurance title",
  insuranceId: "Insurance ID",
  editingPreview: "▶︎ Editing Preview",
  sortBy: "Sort by",
  priority: "Priority",
  dealingStep: "Dealing Step",
  userMemo: "User Memo",
  shortIntroductionSentences: "Short Introduction Sentences",
  introductionSentences: "Introduction Sentences",
  recommendedSentencesList: "Recommended Sentences List",
  hotelSummaryReviews: "Hotel Summary Reviews",
  roomsLeft: "{{num}} rooms left",
  cancelFree: "There is no cancellation charge",
  tags: "Tags",
  enabling: "Enabling",
  priceRange: "Price range",
  hotelRank: "Hotel Rank",
  review: "Review",
  orMore: "or more",
  exist: "Exist",
  notExist: "Not exist",
  pricePerPersonPerNight: "Price per person, per night",
  flightIntroduction: "Flight Introduction",
  flightReservation: "Flight Reservation",
  tripPlans: "Trip Plans",
  hotelReservation: "Hotel Reservation",
  dynamicPackageReservation: "DP Reservation",
  editReservation: "Edit Reservation",
  hotelArrangementContents: "Hotel Arrangement Contents",
  flightArrangementContents: "Flight Arrangement Contents",
  optionalTourArrangementContents: "Optional Tour Arrangement Contents",
  rentalCarArrangementContents: "Rental Car Arrangement Contents",
  wifiArrangementContents: "WiFi Arrangement Contents",
  railwayArrangementContents: "Railway Arrangement Contents",
  cruiseArrangementContents: "Cruise Arrangement Contents",
  insuranceArrangementContents: "Insurance Arrangement Contents",
  otherArrangementContents: "Other Arrangement Contents",
  discountContents: "Discount Contents",
  userInformation: "User Information",
  paymentDetails: "Payment Details",
  chargeInformation: "Charge Information",
  passenger: "Passenger",
  guest: "Guest",
  companion: "Companion",
  hotelId: "Hotel ID",
  hotelName: "Hotel",
  roomName: "Room name",
  mealType: "Meal type",
  paymentType: "Payment type",
  numberOfPeopleToStay: "Number of people to stay",
  noCancellations: "No cancellations",
  itineraryNumber: "Itinerary number",
  theTimeWhenARateOccurs: "The time when a rate occurs",
  thisReservationIsManagedByBookingCom:
    "This reservation is managed by Booking.com.",
  thisReservationIsManagedByTaap: "This reservation is managed by Taap.",
  thisReservationIsManagedByJetstar: "This reservation is managed by Jetstar.",
  wholesaler: "Wholesaler",
  plan: "Plan",
  paymentName: "Payment name",
  creditCardUrl: "Credit card URL",
  bankTransferUrl: "Bank transfer URL",
  paymentMethod: "Payment method",
  paymentInformation: "Payment Information",
  paymentPrice: "Payment price",
  arrangement: "Arrangement",
  route: "Route",
  flight: "Flight",
  airline: "Airline",
  marketingAirline: "Marketing airline",
  operatingAirline: "Operating airline",
  operatingAirlineShort: "Operating",
  excludeAirline: "Airline to exclude",
  flightNumber: "Flight number",
  departureAirport: "Departure airport",
  arrivalAirport: "Arrival airport",
  transitAirport: "Transit airport",
  transfer: "Transfer",
  airlineID: "Airline ID",
  airportID: "Airport ID",
  numberOfAdults: "Number of adults",
  tripType: "Trip type",
  hotel: "hotel",
  overseasFlight: "Overseas flight",
  domesticFlight: "Domestic flight",
  overseasDynamicPackage: "Overseas DP",
  domesticDynamicPackage: "Domestic DP",
  cruise: "Cruise",
  totalPeople: "Number of people includes children",
  evaluation: "Evaluation",
  toAirport: "To {{name}} ",
  remainingPrice: "Remaining price",
  refundAmount: "Refund amount",
  refundAmountFromSupplier: "Refund amount from supplier",
  refundedDate: "Refunded date",
  refundedFeeAmount: "Refund fee amount we recieve",
  refundedAmountToUser: "Refund amount to customer",
  refundPaymentType: "Refund type",
  autoReply: "Auto Reply",
  reminder: "Reminder",
  scheduledSending: "Scheduled Sending",
  paymentDate: "Payment date",
  displayedPrice: "Displayed price",
  difference: "difference",
  commissionBackPrice: "Commission back price",
  commissionBackDate: "Commission back date",
  multiCity: "multi city",
  itineraries: "itineraries",
  category: "category",
  flightType: "flight type",
  section: "section",
  request: "request",
  maxPricePerPerson: "max price per person",
  minPricePerPerson: "min price per person",
  partner: "partner",
  family: "family",
  friend: "friend",
  single: "single",
  business: "business",
  hopeTravel: "Hope of the travel",
  selectionCriteria: "Selection Criteria",
  traveler: "Traveler",
  none: "None",
  reminderDate: "Date",
  hour: "h",
  minute: "m",
  hearingMode: "Hearing mode",
  notes: "Notes",
  checkpoint: "Important points",
  recommendedPoints: "Recommended points",
  planContents: "Plan contents",
  rating: "Rating",
  flightDetails: "Flight details",
  age: "Age",
  referenceNumber: "Airline reference number",
  hotelReservationId: "Hotel reservation number",
  hopeForHotel: "Hope for hotel",
  hopeForFlight: "Hope for flight",
  quickReplyActionData: "Action data",
  quickReplyLabel: "Label",
  quickReplyDisplayText: "Display text",
  baggage: "Baggage",
  itinerary0Baggage: "Outward baggage",
  itinerary1Baggage: "Return baggage",
  otherPerFlightFees: "Other fees (per flight)",
  arrangementInvoicedPrice: "Only invoiced price",
  arrangementPriceAndFeeSummary: "Arrangement+Fee",
  paymentPriceSummary: "Payment summary",
  revenue: "Revenue",
  layover: "Stop hour",
  minLayover: "Min stop hour",
  maxLayover: "Max stop hour",
  useThis: "Use this",
  departureHour1: "Departure hour 1",
  departureHour2: "Departure hour 2",
  departureHour3: "Departure hour 3",
  departureHour4: "Departure hour 4",
  departureHour5: "Departure hour 5",
  lastDepartureHour: "Last departure hour",
  lastArrivalHour: "Last arrival hour",
  minPrice: "Min price",
  maxPrice: "Max price",
  lowestPrice: "Lowest price",
  arrangementDate: "Arrangement Date",
  discountType: "Discount type",
  priceCurrency: "Currency",
  region: "Regions",
  recordedDate: "Date Recorded",
  or: "or",
  username: "Username",
  chatId: "Chat ID",
  amenity: "Amenities",
  stayDuration: "Stay duration",
  addPeriod: "+ Add period",
  message: "Message",
  scheduledAt: "Scheduled at",
  meal: "Meal",
  cleanliness: "Cleanliness",
  comfort: "Comfort",
  service: "Service",
  guestRoom: "Room",
  internet: "Internet",
  onsen: "Hot spring",
  kosupa: "Cost performance",
  location: "Location",
  landscape: "Landscape",
  quality: "Quality",
  transactionCompleteDate: "Transaction complete date",
  billingDate: "Billing date",
  totalRefundedAmount: "Total refunded amount",
  totalRefundFee: "Total refund fee",
  billerType: "Biller",
  billerName: "Biller company name",
  billerCountryCode: "Biller's country code",
  billerReservationNumber: "Reservation number of Biller",
  serviceType: "Service type",
  hotelPrice: "Hotel price",
  flightPrice: "Flight price",
  optionalTourPrice: "Optional tour price",
  railwayPrice: "Railway price",
  additionalBaggagePrice: "Additional baggage price",
  deadlineDate: "Deadline",
  optionType: "Option type",
  content: "Content",
  autoInputTraveler: "Automatic input of traveler name",
  updatedDesc: "Updated at (DESC)",
  updatedAsc: "Updated at (ASC)",
  lastMessageCreatedDesc: "MSG Created at (DESC)",
  lastMessageCreatedAsc: "MSG Created at (ASC)",
  showAllFilter: "Show all filters",
  showLessFilters: "Show less filters",
  optional: "Optional",
  smoking: "Smoking",
  noSmoking: "No smoking",
  billingPrice: "Billing price",
  transferFee: "Transfer fee",
  paymentFee: "Payment fee",
  members: "Members",
  type: "Type",
  executeDateTime: "Execute Date",
  remarks: "Remarks",
  targetDate: "Target Date",
  daysToShift: "Days To Shift",
  label: "Label",
  displayText: "Display text",
  button: "Button",
  carousel: "Carousel",
  chatUnitStatus: "Chat status",
  title: "Title",
  underage: "Underage",
  true: "YES",
  false: "NO",
  period: "Period",
  planId: "Plan ID",
  refundsToUser: "Refunds to users",
  noRefundsToUser: "No refunds to users",
  noRefundsForUser: "No refunds for users",
  edit: "Edit",
  externalImage: "External Image",
  transferDate: "Transfer Date",
  required: "Required",
  checkpoints: "Checkpoints",
  deadline: "Deadline",
  relayEmail: "Forwarded email",
  operatorOnlyEmail: "OP only email",
  email: "Email",
  relayToEmail: "Forwarding email",
  priceDifferenceDisplayable: "Price difference display",
  showPriceDifference: "Show price difference",
  uri: "URL",
  action: "Action",
  noData: "No Data",
  unselected: "Unselected",
};
