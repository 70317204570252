import * as React from "react";
import Link from "next/link";
import { css } from "aphrodite";
import { HeaderStyle } from "./HeaderStyle";
import { breakpointValue } from "main/javascripts/styles/base/responsiveStyle";
// import { AButton } from "main/javascripts/components/atoms/AButton";
import logoImg from "main/javascripts/images/logo.svg";
import { User } from "main/javascripts/types/user";
import { calcScrollBarWidth } from "main/javascripts/utils/ScrollUtil";

export interface IProps {
  currentUser: User.User | null;
}

export interface IActionsProps {
  actions: {
    setActiveBreakpoint: any;
  };
}

export class Header extends React.Component<IProps & IActionsProps> {
  private mediaQueryData: { [key: string]: MediaQueryList };
  constructor(props: any) {
    super(props);
    this.mediaQueryData = {};
  }

  public componentDidMount(): void {
    breakpointValue.forEach((value: number, key: string) => {
      const query: MediaQueryList = window.matchMedia(
        `(min-width: ${value}px)`
      );
      const breakpointChange: any = (): void => {
        this.dispatchActiveQuery();
      };
      query.addListener(breakpointChange);
      this.mediaQueryData = { [key]: query, ...this.mediaQueryData };
    });
    this.dispatchActiveQuery();
    calcScrollBarWidth();
  }

  private dispatchActiveQuery(): void {
    let queryKey = "phone";
    breakpointValue.forEach((_: number, key: string) => {
      const query: MediaQueryList = this.mediaQueryData[key];
      if (query.matches) {
        queryKey = key;
        return;
      }
    });
    this.props.actions.setActiveBreakpoint(queryKey);
  }

  public render(): JSX.Element {
    // const { currentUser } = this.props;
    return (
      <div className={css(HeaderStyle.block)}>
        <div className={css(HeaderStyle.logoBlock)}>
          <Link href="/" passHref>
            <a>
              <img src={logoImg} alt="logo" className={css(HeaderStyle.logo)} />
            </a>
          </Link>
        </div>
        {/* <AButton
          styles={{block: HeaderStyle.buttonBlock, button: HeaderStyle.button}}
          href={currentUser ? "/reservations" : "/users/sign_in"}
        >
          <div>{currentUser ? "マイページ" : "ログイン"}</div>
        </AButton> */}
      </div>
    );
  }
}
