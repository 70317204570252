import { common } from "./common/ja";
import { flightReservationNew } from "./flightReservationNew/ja";
import { flightReservationShow } from "./flightReservationShow/ja";
import { flightIndex } from "./flightIndex/ja";
import { hotelReservationShow } from "./hotelReservationShow/ja";
import { hotelReservationNew } from "./hotelReservationNew/ja";
import { hotelIndex } from "./hotelIndex/ja";
import { hotelShow } from "./hotelShow/ja";
import { reservationShow } from "./reservationShow/ja";
import { search } from "./search/ja";
import { form } from "./form/ja";
import { modal } from "./modal/ja";
import { consultationNew } from "./consultationNew/ja";
import { dynamicPackage } from "./dynamicPackage/ja";
import { dynamicPackageReservationNew } from "./dynamicPackageReservationNew/ja";
import { consultationConditionEdit } from "./consultationConditionEdit/ja";
import { consultationConditionShow } from "./consultationConditionShow/ja";
import { carouselFlight } from "./carouselFlight/ja";
import { error } from "./error/ja";
import { lp } from "./lp/ja";
import { cms } from "./cms/ja";
import { reservation } from "./reservation/ja";
import { flightRegion } from "./flightRegion/ja";
import { hotelRegion } from "./hotelRegion/ja";
import { chargeInfoShow } from "./chargeInfoShow/ja";
import { costItem } from "./costItem/ja";
import { post } from "./post/ja";
import { reputation } from "./reputation/ja";
import { ja as emailUser } from "./emailUser/ja.yml";
import { ja as line } from "./line/ja.yml";
import { ja as consultation } from "./consultation/ja.yml";
import { ja as cruise } from "./cruise/ja.yml";

export const componentJa: any = {
  common,
  flightReservationNew,
  flightReservationShow,
  flightIndex,
  hotelReservationShow,
  hotelReservationNew,
  hotelIndex,
  hotelShow,
  reservationShow,
  search,
  form,
  modal,
  consultationNew,
  dynamicPackage,
  dynamicPackageReservationNew,
  consultationConditionEdit,
  consultationConditionShow,
  carouselFlight,
  error,
  lp,
  cms,
  reservation,
  flightRegion,
  hotelRegion,
  chargeInfoShow,
  costItem,
  post,
  reputation,
  emailUser,
  line,
  consultation,
  cruise,
};
